import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const postOutputAggregateReports = async (
  yearMonth: string,
  aggregateType: "products" | "tax",
  agencyCode: string
) => {
  const path = "/aggregate_reports/output";
  const postData = {
    year_month: yearMonth,
    aggregate_type: aggregateType,
    agency_code: agencyCode,
  };
  const params = { body: postData };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
