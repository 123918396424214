import { FC, useEffect, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { AgencyMenuItem } from "../../common/type";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "../common/Alert";
import * as luxon from "luxon";
import { postOutputAggregateReports } from "../../api/postOutputAggregateReports";

type Props = {
  modalTitle: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  agenciesList: AgencyMenuItem[];
  selectedAgencyCode: string;
  setSelectedAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  aggregateTypes: string[];
};

const NotificationDocsAggregateModal: FC<Props> = ({
  modalTitle,
  isModalOpen,
  setIsModalOpen,
  agenciesList,
  selectedAgencyCode,
  setSelectedAgencyCode,
  aggregateTypes,
}) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  // 対象年月リスト
  const [yearLabels, setYearLabels] = useState<string[]>([]);
  const [yearValues, setYearValues] = useState<string[]>([]);
  const [monthLabels, setMonthLabels] = useState<string[]>([]);
  const [monthValues, setMonthValues] = useState<string[]>([]);

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isPending, setIsPending] = useState(false);

  const [selectedAggregateType, setSelectedAggregateType] = useState<
    "products" | "tax"
  >("products");

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  //対象年月リスト作成
  useEffect(() => {
    const today = luxon.DateTime.now();
    const yearList = Array.from({ length: 3 }, (_, i) =>
      today.minus({ years: i })
    );
    const monthList = Array.from({ length: 12 }, (_, i) =>
      today.minus({ months: i + 1 })
    );
    setYearLabels(
      yearList.map((dateTime: luxon.DateTime) => `${dateTime.year}年`)
    );
    setYearValues(
      yearList.map((dateTime: luxon.DateTime) => `${dateTime.year}`)
    );
    setMonthLabels(
      monthList.map((dateTime: luxon.DateTime) => `${dateTime.month}月`)
    );
    setMonthValues(
      monthList.map((dateTime: luxon.DateTime) => `${dateTime.month}`)
    );
  }, []);

  const handleClick = async () => {
    setAlertTitle("明細集計");
    setIsPending(true);
    try {
      const res = await postOutputAggregateReports(
        selectedMonth.length === 1
          ? `${selectedYear}0${selectedMonth}`
          : `${selectedYear}${selectedMonth}`,
        selectedAggregateType,
        selectedAgencyCode
      );
      if (res.statusCode === 200) {
        setAlertTitle("商品明細ダウンロード");
        setAlertMessage(
          "明細集計を開始しました。\n完了するまでしばらく時間がかかります。\n完了したらメールでお知らせします。"
        );
        setIsAlert(true);
      } else {
        setAlertTitle("商品明細ダウンロード");
        setAlertMessage(res?.message);
        setIsAlert(true);
      }
    } catch (e) {
      setAlertMessage("明細集計が開始できませんでした。" + e);
      setIsAlert(true);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen}>
      <h3 className="flex justify-between text-xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {modalTitle}
      </h3>
      <div className="flex flex-col gap-5">
        <div className="mt-5 flex items-center justify-center w-full gap-5">
          <FormControl sx={{ width: "100%" }}>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              displayEmpty
              // disabled={downloadingFileType !== ""}
              className="text-left"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <em className="text-textSub">対象年を選択してください</em>
              </MenuItem>
              {yearLabels.map((year: string, i: number) => (
                <MenuItem key={i} value={yearValues[i]}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              displayEmpty
              // disabled={downloadingFileType !== ""}
              className="text-left"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <em className="text-textSub">対象月を選択してください</em>
              </MenuItem>
              {monthLabels.map((month: string, i: number) => (
                <MenuItem key={i} value={monthValues[i]}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex items-center justify-center w-full gap-5">
          <FormControl sx={{ width: "100%" }}>
            <Select
              defaultValue={selectedAgencyCode}
              value={selectedAgencyCode}
              onChange={(e) => setSelectedAgencyCode(e.target.value as string)}
              displayEmpty
              // disabled={downloadingFileType !== ""}
              className="text-left"
              inputProps={{ "aria-label": "Without label" }}
            >
              {agenciesList.map((agency: AgencyMenuItem) => (
                <MenuItem key={agency.code} value={agency.code}>
                  {agency.code !== "" && "[" + agency.code + "] "}
                  {agency.name?.length > 20
                    ? `${agency.name.slice(0, 20)}...`
                    : agency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex items-center justify-center w-full gap-5">
          <FormControl sx={{ width: "100%" }}>
            <Select
              value={selectedAggregateType === "products" ? "0" : "1"}
              onChange={(e) =>
                setSelectedAggregateType(
                  e.target.value === "0" ? "products" : "tax"
                )
              }
              displayEmpty
              // disabled={downloadingFileType !== ""}
              className="text-left"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <em className="text-textSub">集計対象を選択してください</em>
              </MenuItem>
              {aggregateTypes.map((aggregateType: string, i: number) => (
                <MenuItem key={i} value={String(i)}>
                  {aggregateType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="mt-8 grid grid-flow-row-dense grid-cols-2 gap-4">
        <Button
          onClick={() => closeModal()}
          disabled={!isModalOpen}
          buttonType={"normal"}
        >
          閉じる
        </Button>
        <Button
          onClick={() => handleClick()}
          disabled={selectedYear === "" || selectedMonth === ""}
          isLoading={isPending}
        >
          集計
        </Button>
      </div>
      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={async () => {}}
        title={alertTitle}
        submitText={""}
        cancelText={"閉じる"}
        message={""}
        setMessage={async () => {}}
      >
        {alertMessage}
      </Alert>
    </Modal>
  );
};

export default NotificationDocsAggregateModal;
